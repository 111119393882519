import { ProductosService } from 'src/app/services/productos.service';
import { Usuario } from 'src/models';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { skServices } from 'src/app/services/sk.services.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { StoreService } from 'src/app/services/store.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { FormProductoUsuarioComponent } from 'src/app/componentes/shared/modal/form-producto-usuario/form-producto-usuario.component';
import { FormNivelComponent } from 'src/app/componentes/shared/modal/form-nivel/form-nivel.component';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from "rxjs";
import Swiper from "swiper/types/swiper-class";
import SwiperCore, {
  Autoplay,
  SwiperOptions,
  EffectFade,
  Scrollbar,
  Navigation,
  Pagination,
  A11y,
  Virtual,
  Zoom,
  Thumbs,
  Controller
} from 'swiper';
SwiperCore.use([
  Autoplay, 
  EffectFade, 
  Scrollbar,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);

import { RouterService } from 'src/app/services/router.service';
import { GetSetAtributosService } from 'src/app/services/get-set-atributos.service';
import { ModalProductosComponent } from 'src/app/componentes/modal-productos/modal-productos.component';
import { NotificacionesSevices } from 'src/app/services/notificaciones-sevices.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormProductoVendedorComponent } from 'src/app/componentes/shared/modal/form-producto-vendedor/form-producto-vendedor.component';
import { FormEnlacesComponent } from 'src/app/componentes/shared/modal/form-enlaces/form-enlaces.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  @ViewChild(HomePage) child;

  indexCard: any;
  bras:any = environment.bras;
  lingerie:any = environment.lingerie;
  socks:any = environment.socks;
  panties:any = environment.panties;
  costumes:any = environment.costumes;
  toys:any = environment.toys;
  nightwear:any = environment.nightwear;
  swimwear:any = environment.swimwear;
  otros:any = environment.otros;
  altura:any = environment.altura_img;
  menu:any = 'adviser';
  producto_general:any;
  producto_general_nuevo:any;
  vendor:any = [];
  config2: SwiperOptions = {
    initialSlide: 1,
    direction: 'vertical',
    slidesPerView: 1,
    spaceBetween: 10,
    effect: 'slide',
  };

  Images2: any = [
    {
      activate: 'like',
    },
    {
      activate: 'null',
    },
    {
      activate: 'dislike',
    },
  ];

  config: SwiperOptions = {
    autoplay: true,
    keyboard: true,
    slidesPerView: 3,
    pagination:{ clickable: true },
    navigation:true,
    spaceBetween: 50,
    scrollbar: { draggable: true },
  };
  configsm: SwiperOptions = {
    autoplay: true,
    keyboard: true,
    slidesPerView: 1,
    pagination:{ clickable: true },
    spaceBetween: 50,
    scrollbar: { draggable: true },
  };

  fecha:any = new Date().getFullYear();
  	xfecha = this.fecha;
	userData: any;
	productos: any = [];
	vendedor: any = [];
	arry: any = [];
	arry2: any = [];
	arry3: any = [];
	arry4: any = [];
	data: any = [];
	dataI: any = [];
  des_deposito: any;
	dep: any = [];
	productos_mmbr: any = [];
	pruebaBack: any;
	category: any = localStorage.getItem('usuario_categoria');
  tipo_usuario:any = localStorage.getItem('usuario_tipo');
  currentPage = 1;
  pageOfItems: Array<any>;

  slides: any[] = new Array(3).fill({id: -1, src: '', title: '', subtitle: ''});
  unicos: any = [];
  desc_producto: any = [];
  desc_producto_ramdon: any =[];
  lg:any = localStorage.getItem('lg');
  visitas_noti: any = '0';
  visitas_noti_cadena: any;
  data_filtro: any;
  forma:FormGroup;
  constructor(
		private userControl: AuthGuardServicesService,
		private productosService: ProductosService,
    private skservice: skServices,
    private util: UiKitController,
    private router: Router,
    private fb:FormBuilder,
    private route_app: RouterService,
    public menucontroler: MenuController,
    public firebaseauthService: AuthGuardServicesService,
    public firestorangeService: StoreService,
    public modalController: ModalController,
    public carritoService: CarritoService,
    public productosControl: ProductosService,
    private getSet: GetSetAtributosService,
    private notificaciones: NotificacionesSevices,

	) {
    this.forma = this.fb.group({
			termino: ['']
		  });
  }

  @HostListener('ionScroll', ['$event'])
  onWindowScroll($event: any): void {
    const promotionHeader = document.querySelector('.pie-pagina ') as HTMLElement;
var num = $event.detail.deltaY;
    if (num < 0) {
      promotionHeader.classList.remove('nav_bar_up')
      promotionHeader.classList.add('nav_bar_down');
    }else{
      promotionHeader.classList.remove('nav_bar_down')
      promotionHeader.classList.add('nav_bar_up');
    }
  }
  
  ngOnInit() {
    if (localStorage.getItem('usuario_tipo').toLowerCase() === 'mmbr' ||  localStorage.getItem('usuario_tipo').toLowerCase() === 'vstnt') {
      /* this.listarProductos('1'); */
      /* this.getusuarios(); */
    }
  }
  ionViewDidEnter() {
    this.menu = 'adviser';
    this.lg = localStorage.getItem('lg');
    console.log('ionViewDidEnter');
    if (localStorage.getItem('usuario_tipo').toLowerCase() === 'mmbr' ||  localStorage.getItem('usuario_tipo').toLowerCase() === 'vstnt') {
      this.listarProductos(null);
      this.getusuarios();
    }
    if (localStorage.getItem('usuario_tipo').toLowerCase() === 'dlr' ) {
      /* this.listarProductosControl(); */
    }
    if (localStorage.getItem('usuario_tipo').toLowerCase() === 'prdr' ) {
      this.listarDepControlOperador();
      this.listarDepOperador('1')
    }
		if(localStorage.getItem('userId')){
      const tipo = localStorage.getItem('tipo');
    }else{
      /* await this.userControl.usuarioVisitante(); */
    }
		/* this.userData = this.userControl.Usuario; */
    this.notificaciones.getNotiVisitas().subscribe((res) => {
			this.visitas_noti_cadena = res;
      this.visitas_noti = this.visitas_noti_cadena.vistas.toString();
			console.log(this.visitas_noti);
		});
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  getProductoTipoFiltro(xvalor:any, xobjeto:any){
    try {
      var objeto = {
        tipo_producto_cat: xobjeto
      }
      this.getSet.parametro = objeto;
        this.route_app.rutas_app(xvalor);
    } catch (error) {
      console.log(error);
    }
  }
	async getData(){
    try {
      if(localStorage.getItem('tipoUsuario') !== 'cliente'){
        this.productos = await this.productosService.getProductos()
        /* this.productos = await this.productosService.getProductosStatic(); */
      }
    } catch (error) {
      console.log(error);
    }
  }
  async listarDepOperador(msg:any){
    try {
      var xvalor = {
        cod_prdr:localStorage.getItem('cod_usuario')
      }
      
     const response =  await this.skservice.getOperadorVendedorhttp(xvalor).toPromise();
     console.log(response);
     let objeto_producto = [];
     let objeto_producto_nuevo = [];
     for (const i in response.productos) {
      if (response.productos[i].cat_producto) {
        objeto_producto.push(response.productos[i]);
      }else{
        objeto_producto_nuevo.push(response.productos[i]);
      }
     }
    
     this.producto_general = [...objeto_producto];
     this.producto_general_nuevo = [...objeto_producto_nuevo];
     this.vendor = [...response.vendor];
     if (msg !== null) {
      
    }
    } catch (error) {
      console.log(error);
    }
  }
  async getProductoGeneral(){
		try {
		  await this.util.cargando();
		  const modal = await this.modalController.create({
			component: FormProductoVendedorComponent,
			cssClass: 'form-pedidos',
			componentProps: { data:this.producto_general,
                        termino:'Product'},
			backdropDismiss: false
		  });
		  await this.util.closeLoading();
		  await modal.present();
		  const { data } = await modal.onWillDismiss();
		  return modal;
		} catch (error) {
		  console.log(error);
      await this.util.closeLoading();
			await this.util.ToastWarning(error.error.message);
		}
		
	  }
  async getProductoGeneralNuevo(){
		try {
		  await this.util.cargando();
		  const modal = await this.modalController.create({
			component: FormProductoVendedorComponent,
			cssClass: 'form-pedidos',
			componentProps: { data:this.producto_general_nuevo,
                        termino:'New Product'},
			backdropDismiss: false
		  });
		  await this.util.closeLoading();
		  await modal.present();
		  const { data } = await modal.onWillDismiss();
		  return modal;
		} catch (error) {
		  console.log(error);
      await this.util.closeLoading();
			await this.util.ToastWarning(error.error.message);
		}
		
	  }
  async listarProductos(msm:any){
    try {
      this.data = [];
      this.productos = [];
      await this.util.cargando();
      let xvalor = {
        cod_usuario:localStorage.getItem('cod_usuario')
      }
      const response_b =  await this.skservice.getUsuarioVendedorBloqueadohttp(xvalor).toPromise();
      console.log(response_b);
        let bloqueos_usuarios = [];
          for (const i in response_b.usuario) {
            bloqueos_usuarios.push(response_b.usuario[i]);
        }
      const response_l =  await this.skservice.getListaNegraUsuarioVendedorhttp(xvalor).toPromise();
     console.log(response_l);
     let lista_negra = [];
       for (const i in response_l.usuario) {
        lista_negra.push(response_l.usuario[i]);
     }
     const response =  await this.skservice.getProdhttp(xvalor).toPromise();
     
     console.log(response);
     for (const i in response.producto) {
        if (response.producto[i].um_producto_operador &&
           response.producto[i].cat_producto &&
           response.producto[i].tipo_producto_cat &&
            response.producto[i].estatus === 'A') {
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'platinum') {
              this.productos.push(response.producto[i]);
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'gold') {
              if (response.producto[i].cat_producto.toLowerCase() === 'gold' ||
                  response.producto[i].cat_producto.toLowerCase() === 'silver' ||
                  response.producto[i].cat_producto.toLowerCase() === 'basic') {
                    this.productos.push(response.producto[i]);
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'silver') {
              if (response.producto[i].cat_producto.toLowerCase() === 'silver' ||
                  response.producto[i].cat_producto.toLowerCase() === 'basic') {
                    this.productos.push(response.producto[i]);
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'basic') {
              if (response.producto[i].cat_producto.toLowerCase() === 'basic') {
                    this.productos.push(response.producto[i]);
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'vstnt') {
              if (response.producto[i].cat_producto.toLowerCase() === 'basic') {
                    this.productos.push(response.producto[i]);
              }
            }
        }
     }
     this.desc_producto = [];
     this.desc_producto_ramdon = [];
     this.arry = [];
      this.arry = [...this.productos];
     for (let i = 0; i < 10; i++) {
      if (this.productos[i]) {
        this.desc_producto.push(this.productos[i]);
      }
     }
     for (let i = 0; i < this.productos.length; i++) {
      const item = this.productos[Math.floor(Math.random() * this.productos.length)];
      this.desc_producto_ramdon.push(item);
    }

    this.unicos = this.desc_producto_ramdon.filter((valor, indice) => {
      return this.desc_producto_ramdon.indexOf(valor) === indice;
        }
      );
      console.log(this.arry);
      if (lista_negra.length > 0) {
        for (const i in this.desc_producto) {
          for (const l in lista_negra) {
            if (this.desc_producto[i].cod_deposito === lista_negra[l].cod_vendedor) {
              delete this.arry[i];
            }
          }
      }
      
      this.desc_producto = [];
      for (let i = 0; i < 15; i++) {
       if (this.arry[i]) {
        this.desc_producto.push(this.arry[i]);
       }
      }
      }

      if (bloqueos_usuarios.length > 0) {
        for (const i in this.desc_producto) {
          for (const l in bloqueos_usuarios) {
            if (this.desc_producto[i].cod_deposito === bloqueos_usuarios[l].cod_usuario) {
              delete this.arry[i];
            }
          }
      }
      
      this.desc_producto = [];
      for (let i = 0; i < 15; i++) {
       if (this.arry[i]) {
        this.desc_producto.push(this.arry[i]);
       }
      }
      }
      
     this.data = [...this.desc_producto];
     this.dataI = [...this.unicos];
     await this.util.closeLoading();
     if (msm !== null) {
      await this.util.ToastSuccess(response.message);
     }
     
    } catch (error) {
      await this.util.closeLoading();
      this.data = [];
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async listarProductosRandom(msm:any){
    try {
      this.data = [];
      this.productos = [];
      await this.util.loading();
      let xvalor = {
        cod_usuario:localStorage.getItem('cod_usuario')
      }
     const response =  await this.skservice.getProdhttp(xvalor).toPromise();
     
     console.log(response);
     for (const i in response.producto) {
        if (response.producto[i].um_producto_operador &&
           response.producto[i].cat_producto &&
           response.producto[i].tipo_producto_cat &&
            response.producto[i].estatus === 'A') {
         this.productos.push(response.producto[i]);
        }
     }
     var desc_producto = [];
     for (let i = 0; i < 3; i++) {
        desc_producto.push(this.productos[i]);
     }
     this.data = [...desc_producto];
     console.log(response);
     if (msm !== null) {
      await this.util.ToastSuccess(response.message);
     }
     
    } catch (error) {
      this.data = [];
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async listarProductosControl(){
    try {
      this.productos = [];
      this.productos_mmbr = [];
      let xvalor = {
        cod_usuario:localStorage.getItem('cod_usuario')
      }
      await this.util.loading();
     const response =  await this.skservice.getproductosSellerhttp(xvalor).toPromise();
     
     console.log(response)
     this.productos = [...response.producto];
     if (localStorage.getItem('usuario_tipo').toLowerCase() === 'dlr') {
      for (const i in this.productos) {
        if (this.productos[i].cod_deposito === localStorage.getItem('cod_deposito')) {
          this.productos_mmbr.push(this.productos[i]);
        }
      }
      this.productos = [...this.productos_mmbr];
     }
     
     await this.util.ToastSuccess(response.message);
    } catch (error) {
      this.productos = [...this.productos_mmbr];
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async listarProductosBuscar(){
    try {
      this.data = [];
      this.productos = [];
      await this.util.cargando();
      if (!this.forma.value.termino) {
        await this.util.closeLoading();
       await this.listarProductos(null);
        return;
      }
      let xvalor = {
        cod_usuario:localStorage.getItem('cod_usuario'),
        termino:this.forma.value.termino
      }
      const response_b =  await this.skservice.getUsuarioVendedorBloqueadohttp(xvalor).toPromise();
      console.log(response_b);
        let bloqueos_usuarios = [];
          for (const i in response_b.usuario) {
            bloqueos_usuarios.push(response_b.usuario[i]);
        }
      const response_l =  await this.skservice.getListaNegraUsuarioVendedorhttp(xvalor).toPromise();
     console.log(response_l);
     let lista_negra = [];
       for (const i in response_l.usuario) {
        lista_negra.push(response_l.usuario[i]);
     }
     const response =  await this.skservice.getProdBuscarttp(xvalor).toPromise();
     
     console.log(response);
     for (const i in response.producto) {
        if (response.producto[i].um_producto_operador &&
           response.producto[i].cat_producto &&
           response.producto[i].tipo_producto_cat &&
            response.producto[i].estatus === 'A') {
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'platinum') {
              this.productos.push(response.producto[i]);
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'gold') {
              if (response.producto[i].cat_producto.toLowerCase() === 'gold' ||
                  response.producto[i].cat_producto.toLowerCase() === 'silver' ||
                  response.producto[i].cat_producto.toLowerCase() === 'basic') {
                    this.productos.push(response.producto[i]);
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'silver') {
              if (response.producto[i].cat_producto.toLowerCase() === 'silver' ||
                  response.producto[i].cat_producto.toLowerCase() === 'basic') {
                    this.productos.push(response.producto[i]);
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'basic') {
              if (response.producto[i].cat_producto.toLowerCase() === 'basic') {
                    this.productos.push(response.producto[i]);
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'vstnt') {
              if (response.producto[i].cat_producto.toLowerCase() === 'basic') {
                    this.productos.push(response.producto[i]);
              }
            }
        }
     }
     this.desc_producto = [];
     this.desc_producto_ramdon = [];
     this.arry = [];
      this.arry = [...this.productos];
     for (let i = 0; i < 10; i++) {
      if (this.productos[i]) {
        this.desc_producto.push(this.productos[i]);
      }
     }
     for (let i = 0; i < this.productos.length; i++) {
      const item = this.productos[Math.floor(Math.random() * this.productos.length)];
      this.desc_producto_ramdon.push(item);
    }

    this.unicos = this.desc_producto_ramdon.filter((valor, indice) => {
      return this.desc_producto_ramdon.indexOf(valor) === indice;
        }
      );
      console.log(this.arry);
      if (lista_negra.length > 0) {
        for (const i in this.desc_producto) {
          for (const l in lista_negra) {
            if (this.desc_producto[i].cod_deposito === lista_negra[l].cod_vendedor) {
              delete this.arry[i];
            }
          }
      }
      
      this.desc_producto = [];
      for (let i = 0; i < 15; i++) {
       if (this.arry[i]) {
        this.desc_producto.push(this.arry[i]);
       }
      }
      }

      if (bloqueos_usuarios.length > 0) {
        for (const i in this.desc_producto) {
          for (const l in bloqueos_usuarios) {
            if (this.desc_producto[i].cod_deposito === bloqueos_usuarios[l].cod_usuario) {
              delete this.arry[i];
            }
          }
      }
      
      this.desc_producto = [];
      for (let i = 0; i < 15; i++) {
       if (this.arry[i]) {
        this.desc_producto.push(this.arry[i]);
       }
      }
      }
      
     this.data = [...this.desc_producto];
     this.dataI = [...this.unicos];
      await this.util.closeLoading();
    } catch (error) {
      await this.util.closeLoading();
      this.data = [];
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async listarDepControlOperador(){
    try {
      this.dep  = [];
      await this.util.cargando();
     const response =  await this.skservice.getDepDlrhttp().toPromise();
     this.dep = [...response.dep];
     this.data_filtro = [...response.dep];
     console.log(this.data_filtro);
     await this.util.closeLoading();
     
    } catch (error) {
      console.log(error);
      await this.util.closeLoading();
    }
  }
  async modalUsuarioProdcutos(xvalor:any){
    try {
      
      await this.util.loading();
      var xval = {
        cod_producto:xvalor.cod_producto,
        cod_deposito:xvalor.cod_deposito
      }
      const xobjeto = await this.skservice.getProductoActivoHomehttp(xval).toPromise();
      console.log(xobjeto);
      if (xobjeto.resul[0].estatus === 'A') {
        if (localStorage.getItem('usuario_tipo').toLowerCase() !== 'dlr') {
          if (localStorage.getItem('usuario_tipo').toLowerCase() === 'mmbr') {
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'platinum') {
              this.getSet.producto = xvalor;
               this.route_app.rutas_app('producto_usuario');
              
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'gold') {
              if (xobjeto.resul[0].cat_producto.toLowerCase() === 'gold' ||
               xobjeto.resul[0].cat_producto.toLowerCase() === 'silver' ||
               xobjeto.resul[0].cat_producto.toLowerCase() === 'basic') {
                this.getSet.producto = xvalor;
                this.route_app.rutas_app('producto_usuario');
              }else{
                
                const modal = await this.modalController.create({
                  component: FormNivelComponent,
                  cssClass: 'form-usuario-producto',
                  componentProps: { data:xvalor},
                  backdropDismiss: false
                });
                await modal.present();
                const { data } = await modal.onWillDismiss();
                if (data !== undefined && data !== null && data !== '') {
                  console.log(data);
                  await this.router.navigate(['proveedor',data.cod_usuario+'//'+data.cod_deposito]);
                }
                return modal;
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'silver') {
              if (xobjeto.resul[0].cat_producto.toLowerCase() === 'silver' ||
               xobjeto.resul[0].cat_producto.toLowerCase() === 'basic') {
                this.getSet.producto = xvalor;
                this.route_app.rutas_app('producto_usuario');
              }else{
                
                const modal = await this.modalController.create({
                  component: FormNivelComponent,
                  cssClass: 'form-usuario-producto',
                  componentProps: { data:xvalor},
                  backdropDismiss: false
                });
                await modal.present();
                const { data } = await modal.onWillDismiss();
                if (data !== undefined && data !== null && data !== '') {
                  console.log(data);
                  await this.listarProductos(null);
                  await this.router.navigate(['proveedor',data.cod_usuario]);
                }
                return modal;
              }
            }
            if (localStorage.getItem('usuario_categoria').toLowerCase() === 'basic') {
              if (xobjeto.resul[0].cat_producto.toLowerCase() === 'basic') {
                this.getSet.producto = xvalor;
                this.route_app.rutas_app('producto_usuario');
              }else{
                
                const modal = await this.modalController.create({
                  component: FormNivelComponent,
                  cssClass: 'form-usuario-producto',
                  componentProps: { data:xvalor},
                  backdropDismiss: false
                });
                await modal.present();
                const { data } = await modal.onWillDismiss();
                if (data !== undefined && data !== null && data !== '') {
                  console.log(data);
                  await this.listarProductos(null);
                  await this.router.navigate(['proveedor',data.cod_usuario]);
                }
                return modal;
              }
            }
          }else{
            if (xobjeto.resul[0].cat_producto.toLowerCase() === 'basic') {
              console.log(xvalor);
               this.getSet.producto = xvalor;
               this.route_app.rutas_app('producto_usuario');
            }else{
              
                await this.util.ToastWarning('You must acquire a  membership To buy this product');
                this.router.navigate(['/membresia_visitante']);
            }
          }
        }else{
          
          await this.util.ToastWarning('You must acquire a  membership To buy this product');
        }
      }else{
        
        await this.util.ToastWarning('This product is not currently available');
        this.listarProductos(null);
        
      }
      
    } catch (error) {
      
      console.log(error);
        await this.util.ToastWarning(error.error.message);
    }
    
  }
  async modalUsuarioProductoDlr(xvalor:any){
    try {
      console.log(xvalor);
      await this.util.loading();
              
              const modal = await this.modalController.create({
                component: FormProductoUsuarioComponent,
                cssClass: 'form-usuario-producto',
                componentProps: { data:xvalor},
                backdropDismiss: false
              });
              
              await modal.present();
              const { data } = await modal.onWillDismiss();
              this.listarProductosControl();
              return;
    } catch (error) {
      
      console.log(error);
        await this.util.ToastWarning(error.error.message);
    }
    
  }
  async tomarDeposito(xvalor:any){
    try {
      
      await this.util.cargando();
      var xobjeto = {
        cod_prdr: localStorage.getItem('cod_usuario'),
        cod_deposito:xvalor.cod_deposito
      }
     const response =  await this.skservice.getTomarDepositohttp(xobjeto).toPromise();
     await this.util.closeLoading();
     await this.util.ToastSuccess(response.message);
      await this.listarDepControlOperador();
     this.router.navigate(['/deposito_operador_vendedor']);
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
      await this.util.ToastWarning('This seller have not products');
    }
  }
 async handleChange(event) {
    await this.util.cargando();
    const query = event.target.value.toLowerCase();
   
    this.data = this.desc_producto.filter(d => 
      d.tipo_producto_cat.toLowerCase().indexOf(query) > -1 || 
      d.cat_producto.toLowerCase().indexOf(query) > -1  ||
      d.um_producto_operador.toLowerCase().indexOf(query) > -1  ||
      d.des_usuario.toLowerCase().indexOf(query) > -1  ||
      d.des_producto.toLowerCase().indexOf(query) > -1);

    this.dataI = this.unicos.filter(d => 
      d.tipo_producto_cat.toLowerCase().indexOf(query) > -1 || 
      d.cat_producto.toLowerCase().indexOf(query) > -1  ||
      d.um_producto_operador.toLowerCase().indexOf(query) > -1  ||
      d.des_usuario.toLowerCase().indexOf(query) > -1  ||
      d.des_producto.toLowerCase().indexOf(query) > -1);
    
    console.log(this.data.length);
    await this.util.closeLoading();
    if (this.data.length <= 0 || this.dataI.length <= 0) {
      await this.util.ToastWarning('Without results');
    }else{
      await this.util.ToastSuccess(this.data.length+' Results Found');
    }
  }
  obtenerIndex(element: any) {
    console.log(element);
    this.indexCard = element;
  }

 async getPerfilSeller(xvalor:any) {
  try {
    await this.util.cargando();
    this.getSet.swipe_data = 'home';
      this.router.navigate(['/proveedor',xvalor.cod_usuario]);
    await this.util.closeLoading();
  } catch (error) {
    await this.util.closeLoading();
    console.log(error);
    this.util.ToastError(error.error.message);
  }
  }
 async dislike(xvalor:any) {
    try {
      await this.util.loading();
    var xobjeto = {
      cod_mmbr:localStorage.getItem('cod_usuario'),
      cod_dlr:xvalor.cod_usuario,
      tipo:'0',
    };
    await this.skservice.getEventosLikeDislikehttp(xobjeto).toPromise();
    const button = document.querySelector(
      `#card${this.indexCard}`
    ) as HTMLButtonElement;
    const swiperV = document.querySelector(
      `#swiperVertical${this.indexCard}`
    ) as HTMLDivElement;
    console.log(swiperV);

    setTimeout(() => {
      swiperV.classList.add('animate__animated');
      swiperV.classList.add('animate__fadeOut');
      button.click();
    }, 500);
    } catch (error) {
      this.util.ToastError(error.error.message);
    }
  }
  onSlideChangeVertical([swiper]) {
    console.log(swiper, 'este');
    console.log(swiper.swipeDirection);

    if (swiper.swipeDirection === 'next') {
      /* this.dislike(); */
    }
    if (swiper.swipeDirection === 'prev') {
      /* this.like(); */

      setTimeout(() => {
        swiper.slideTo(1, 100);
      }, 500);

    }
  }
  async  modalActualizarProdcutos(xvalor:any, galeria:any){
    try {
      console.log(xvalor);
      xvalor.operacion = '1';
      xvalor.galeria = galeria;
      await this.util.loading();
      const modal = await this.modalController.create({
        component: ModalProductosComponent,
        cssClass: 'edit_producto',
        componentProps: { data:xvalor},
        backdropDismiss: false
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      console.log(data);
      if (data !== undefined && data !== null && data !== '') {
        console.log(data);
        const response = await this.skservice.getImgProductohttp(data).toPromise();
          await this.util.ToastSuccess(response.message);
          this.listarProductos(null);
      }
      return modal;
    } catch (error) {
      console.log(error);
        await this.util.ToastWarning(error.error.message);
    }
    
    }
    getUrlImg(objeto:any) {
      console.log(objeto.cod_img);
        return 'background: url('+objeto.cod_img+'), background-size:cover';
    }

    async  getusuarios(){
      try {
        this.vendedor = [];
        await this.util.cargando();
        /* const usuario = await this.usuariosControl.getUsuariosFirebase() */
        var data = {
          cod_usuario:localStorage.getItem('cod_usuario')
        };
       const response_ =  await this.skservice.getFavoritoshttp(data).toPromise();
       console.log(response_);
       let favoritos = [];
       for (const i in response_.usuario) {
          favoritos.push(response_.usuario[i]);
     }
     const response_b =  await this.skservice.getUsuarioVendedorBloqueadohttp(data).toPromise();
     console.log(response_b);
        let bloqueos_usuarios = [];
          for (const i in response_b.usuario) {
            bloqueos_usuarios.push(response_b.usuario[i]);
        }
     const response_l =  await this.skservice.getListaNegraUsuarioVendedorhttp(data).toPromise();
     console.log(response_l);
     let lista_negra = [];
       for (const i in response_l.usuario) {
        lista_negra.push(response_l.usuario[i]);
     }
      const response = await this.skservice.getUsuarioSwiperSellerhttp().toPromise();
      console.log(response);
      for (const i in response.usuario) {
        if (response.usuario[i].cod_img === null ||
          response.usuario[i].cod_img === undefined ||
          response.usuario[i].cod_img === '' ) {
            response.usuario[i].cod_img = '../../../assets/img/404.jpg';
        }
        if (
          response.usuario[i].altura_usuario &&
          response.usuario[i].body_data &&
          response.usuario[i].color_usuario &&
          response.usuario[i].des_usuario &&
          response.usuario[i].edad_usuario &&
          response.usuario[i].nom_usuario &&
          response.usuario[i].ojos_usuario &&
          response.usuario[i].pelo_usuario &&
          response.usuario[i].sex_usuario
           ) {
              this.vendedor.push(response.usuario[i]);
        }
      }
  this.arry2 = [];
  console.log( this.vendedor);
  console.log( favoritos);
  this.arry2 = [...this.vendedor];
  if (favoritos.length > 0) {
    for (const i in this.vendedor) {
      for (const l in favoritos) {
        if (this.vendedor[i].cod_usuario === favoritos[l].cod_usuario) {
          delete this.arry2[i];
        }
      }
  }
  
  this.vendedor = [];
  for (let i = 0; i < 15; i++) {
   if (this.arry2[i]) {
    this.vendedor.push(this.arry2[i]);
   }
  }
  }
  this.arry3 = [];
  this.arry3 = [...this.vendedor];
  if (lista_negra.length > 0) {
    for (const i in this.vendedor) {
      for (const l in lista_negra) {
        if (this.vendedor[i].cod_usuario === lista_negra[l].cod_vendedor) {
          delete this.arry3[i];
        }
      }
  }
  
  this.vendedor = [];
  for (let i = 0; i < 15; i++) {
   if (this.arry3[i]) {
    this.vendedor.push(this.arry3[i]);
   }
  }
  }

  this.arry4 = [];
  this.arry4 = [...this.vendedor];
  if (bloqueos_usuarios.length > 0) {
    for (const i in this.vendedor) {
      for (const l in bloqueos_usuarios) {
        if (this.vendedor[i].cod_usuario === bloqueos_usuarios[l].cod_usuario) {
          delete this.arry4[i];
        }
      }
  }
  
  this.vendedor = [];
  for (let i = 0; i < 15; i++) {
   if (this.arry4[i]) {
    this.vendedor.push(this.arry4[i]);
   }
  }
  }
  await this.util.closeLoading();
      console.log( this.vendedor);
      } catch (error) {
        await this.util.closeLoading();
        console.log(error);
      }
    }

    async tienda_app_param(url: any, param:any) {
      try {
        this.route_app.rutas_app_param(url,param);
      } catch (error) {
        console.log(error);
      }
    }
    async tienda_app(url: any) {
      try {
        await this.util.cargando();
        this.router.navigate([url]);
        await this.util.closeLoading();
      } catch (error) {
      }
    }

    async handleChange_prdr(event) {
      await this.util.cargando();
        const query = event.target.value.toLowerCase();
        console.log(query);
        this.data_filtro = this.dep.filter(d => 
          d.des_usuario.toLowerCase().indexOf(query) > -1  ||
          d.sex_usuario.toLowerCase().indexOf(query) > -1  ||
          d.pelo_usuario.toLowerCase().indexOf(query) > -1  ||
          d.pais_usuario.toLowerCase().indexOf(query) > -1  ||
          d.ojos_usuario.toLowerCase().indexOf(query) > -1  ||
          d.nom_usuario.toLowerCase().indexOf(query) > -1  ||
          d.edad_usuario.toLowerCase().indexOf(query) > -1  ||
          d.dir_usuario.toLowerCase().indexOf(query) > -1  ||
          d.color_usuario.toLowerCase().indexOf(query) > -1  ||
          d.body_data.toLowerCase().indexOf(query) > -1  ||
          d.ape_usuario.toLowerCase().indexOf(query) > -1  ||
          d.correo.toLowerCase().indexOf(query) > -1);
        
        console.log(this.data_filtro.length);
        await this.util.closeLoading();
        if (this.data_filtro.length <= 0) {
          await this.util.ToastWarning('Without results');
        }else{
          await this.util.ToastSuccess(this.data_filtro.length+' Resultados encontrados');
        }
   }

   async cambio_menu(xvalor: any) {
    try {
      await this.util.cargando();
        this.menu = xvalor;
      await this.util.closeLoading();
    } catch (error) {
    }
  }
  async mantenimiento(){
    await this.util.cargando();
      await this.util.ToastWarning('This function is under maintenance');
    await this.util.closeLoading();
    
  }
  async enlacesCondiciones(xvalor:any){
    await this.util.cargando();
  const modal = await this.modalController.create({
    component: FormEnlacesComponent,
    cssClass: 'form_enlace',
    componentProps: { data:xvalor},
    backdropDismiss: false
  });
    await modal.present();
    await this.util.closeLoading();
    const { data } = await modal.onWillDismiss();
    
  }
}
