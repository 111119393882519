import { Component, OnInit, Input, Pipe, PipeTransform } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import * as moment from 'moment';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { PipeServicesPipe } from 'src/app/pipes/pipe-services.pipe';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { RouterService } from 'src/app/services/router.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Productos, Usuario } from 'src/models';

@Component({
  selector: 'app-producto-nuevo',
  templateUrl: './producto-nuevo.component.html',
  styleUrls: ['./producto-nuevo.component.scss'],
})

export class ProductoNuevoComponent implements OnInit {

  path:any;
  usuario: Usuario;
  NuevaImagen:any = '';
  NuevoArchivo:any = '';
  validacion: any;

  // variables para experiencia de usuario

  PermitirNuevoProducto = false;
  imagen = false;
  loading: any;
  img:any = '';
  img_count:any = [];
  img_prod:any = '';
  forma: FormGroup;
  objectURL:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';
  croppedImage:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';

  imageChangedEvent: any = '';
  
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  param_img: number;
  nombre_img: any;
  forma2: FormGroup;

  constructor(
    public modalController: ModalController,
    public userProductControl: UserService,
    public firestoreService: StoreService,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public fb: FormBuilder,
    public userControl: AuthGuardServicesService,
    private loadindControl: LoadingController,
    private util: UiKitController,
    private store: StoreService,
    private skservice: skServices,
    private router_app: RouterService,
    private foto: PipeServicesPipe,
    public router: Router,
  ) {
    this.forma = this.fb.group({
      descripcion: [''],
      tipo_producto: ['',[Validators.required, Validators.maxLength(30)]],
      cant_producto: ['1',Validators.required],
      des_producto: ['',[Validators.required, Validators.maxLength(30)]],
      precio_producto: ['',Validators.required],
      cat_producto: [''],
      operacion: ['0'],
      cod_producto: [''],
      cod_img: [''],
    })
  }
  ngOnInit() {

    this.path = `Productos/`
    this.forma2 = this.fb.group({
      img: [''],
      alt:''
    })
  }

  async getimgprod(objeto:any) {
    try {
      await this.util.cargando();
      this.imagen = true;
      this.nombre_img = objeto.target.files[0];
      this.img_count.push(this.img_prod);

      this.objectURL = URL.createObjectURL(objeto.target.files[0]);
      this.imageChangedEvent = objeto;
      this.croppedImage = objeto;
      console.log(this.objectURL);
      await this.util.closeLoading();
    } catch (error) {
      await this.util.closeLoading();
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  ngOnDestroy() {
    URL.revokeObjectURL(this.objectURL);
  }

  async imagenProd(objeto:any) {
    try {
      await this.util.loading();
      if (isNaN(parseFloat(this.forma.value.precio_producto))) {
        await this.util.ToastWarning('Wrong price');
        return;
      }
      if (this.img_prod !== '' && this.nombre_img.name.length <= parseInt(environment.nombre_img) &&  this.nombre_img.name.length >= 1 ) {
        console.log(this.nombre_img );
      const response =  await this.store.CargarImagenPerfil(this.nombre_img);
      var data = {
        cod_usuario: localStorage.getItem('cod_usuario'),
        cod_img: response,
        cod_deposito: localStorage.getItem('cod_deposito'),
        des_producto: this.forma.value.des_producto,
        cod_producto: this.forma.value.cod_producto,
        cant_producto: this.forma.value.cant_producto,
        tipo_producto: this.forma.value.tipo_producto,
        precio_producto: this.forma.value.precio_producto,
        cat_producto: this.forma.value.cat_producto,
        operacion: this.forma.value.operacion,
      }
      
      console.log(data);
      const response_ = await this.skservice.getImgProductohttp(data).toPromise();
        await this.util.ToastSuccess(response_.message);
      this.router_app.rutas_app_param('perfil','productos');
      this.forma.reset();
      
      }else{
        
        if (this.img_prod === '') {
          await this.util.ToastWarning('You must upload an image to continue');
        }
        if (this.img_prod.name.length > parseInt(environment.nombre_img)) {
          await this.util.ToastWarning('Image name must be less than '+environment.nombre_img+' characters');
        }
        if (this.img_prod.name.length < 1) {
          
          await this.util.ToastWarning('You can not upload unnamed images');
        }
        
      }
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  ponerPuntosyComas(event:any, id:any){

    let validacion = '1234567890'
    console.log(event.key)

    let inputCantidad = document.querySelector(`#${id}`) as HTMLInputElement
    if(id != 'cant_ingresada'){

      if(inputCantidad.value.length == 1){
        let noa = inputCantidad.value.split('')
        noa.unshift('0')
        noa.unshift('0')
        inputCantidad.value = noa.join('')
        
      }else if(inputCantidad.value.length == 3){
        let noa = inputCantidad.value.split('')
        noa.unshift('0')
        inputCantidad.value = noa.join('')
      }else if(inputCantidad.value.length >= 4 ){
        let noa:any = inputCantidad.value.split('')
        for(let i = 0 ; i < 2; i++){
          if(noa[0] == 0){
            noa.shift()
          }else{
            break;
          }
        }
        inputCantidad.value = noa.join('')
      }
  
      var num = inputCantidad.value.replace(/\D/g,'');
  
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
      num = num.split('').reverse().join('').replace(/\D/g, "")
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      inputCantidad.value = num;

    }else{

        if(inputCantidad.value.length == 1){
          let noa = inputCantidad.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          noa.unshift('0')
          inputCantidad.value = noa.join('')
          
        }else if(inputCantidad.value.length == 4){
          let noa = inputCantidad.value.split('')
          noa.unshift('0')
          inputCantidad.value = noa.join('')
        }else if(inputCantidad.value.length >= 5 ){
          let noa:any = inputCantidad.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputCantidad.value = noa.join('')
        }
    
        var num = inputCantidad.value.replace(/\D/g,'');
    
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
        
        num = num.split('').reverse().join('').replace(/\D/g, "")
        .replace(/([0-9])([0-9]{3})$/, '$1.$2')
        inputCantidad.value = num;
    }

    if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){

      this.util.ToastWarning('Only numbers');
    
    }
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
}

imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
   
    console.log(event);
    this.param_img = event.cropperPosition.x1+event.imagePosition.x1
    this.img_prod = base64ToFile(event.base64);
   
}

imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
}

cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
}

loadImageFailed() {
    console.log('Load failed');
}

rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
}

rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
}

private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}


flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
}

flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
}

resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
}

zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
}

updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
}
async tienda_app(url: any) {
  try {
    await this.util.loading();
    this.router.navigate([url]);
    
  } catch (error) {
  }
}
}
