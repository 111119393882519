import { Oferta } from './../models';
import { OfertaService } from './services/oferta.service';
import { UiKitController } from './services/uikitcontroller.service';
import { Component, OnInit, LOCALE_ID  } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { AuthGuardServicesService } from './services/auth-guard-services.service';
import { CarritoService } from './services/carrito.service';
import { FirechatService } from './services/firechat.service';
import { NotificacionesSevices } from './services/notificaciones-sevices.service';
import { skServices } from './services/sk.services.service';
import { FormPedidosComponent } from './componentes/shared/modal/form-pedidos/form-pedidos.component';

import { FormVideoMuestraComponent } from './componentes/shared/form-video-muestra/form-video-muestra.component';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';

import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';


@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	env_time:any = environment.time_idle;
	fecha:any = new Date().getFullYear();
	idleState:any;
	timedOut = false;
	lastPing?: Date = null;
	valoroff:any;
  tiempo:any;
  lg:any;
  	xfecha = this.fecha;
	validate = false;
	uid:any = localStorage.getItem('userId');
	image: any = localStorage.getItem('img') && localStorage.getItem('img') !== 'null' ? localStorage.getItem('img') : null;
	notificacionesSinVer = 0;
	ChatsSinVer = 0;
	usuario_tipo:any;
	userData = {
		tipoUsuario:''
	}
	message: any;
	sesion: any = false;
	img: any;
	img_user: any;
	notificationOpen = false;
	notificacionesList = [];

	constructor(
		private autho: AuthGuardServicesService,
		private platform: Platform,
		private notificaciones: NotificacionesSevices,
		private router: Router,
		private chatControl: FirechatService,
		private carritoService: CarritoService,
		private UiKit: UiKitController,
		private ofertaSv: OfertaService,
		private servicio: AuthGuardServicesService,
		private util: UiKitController,
		public firebaseauthService: AuthGuardServicesService,
		public modalController: ModalController,
		private skservice: skServices,
	) {
		console.log(this.uid,'uidnav');
		var lang = localStorage.getItem('lg');
		if (lang) {
			if (lang.toUpperCase() === 'EN') {
				localStorage.setItem('lg', 'en');
				this.lg = localStorage.getItem('lg');
			}
			if (lang.toUpperCase() === 'AL') {
				localStorage.setItem('lg', 'al');
				this.lg = localStorage.getItem('lg');
			}
		}else{
			localStorage.setItem('lg', environment.idioma);
			this.lg = localStorage.getItem('lg');
		}
	}

	ngOnInit() {
		
		//////ocultar spinner sk/////
		this.platform.ready().then(()=>{
			this.UiKit.HideSplash()
		})
		//////ocultar spinner sk/////
		/* this.uid = localStorage.getItem('userId') || null; */
		this.img_user = localStorage.getItem('cod_img');
		this.img = true;
		this.usuario_tipo = localStorage.getItem('usuario_tipo');
		this.notificaciones.getNotificacionesList(this.uid).subscribe((res) => {
			this.notificacionesList = res;
		});
		this.autho.UsuarioSubsCribe().subscribe(res => {
			this.userData = res
			/* this.img = res.photoURL */
			this.img = true;
		})
	  if (this.autho.isLoggedIn) {
			console.log(this.autho.isLoggedIn);
			this.validate = true;
			this.notificaciones.getNotificaciones(this.uid).subscribe((res: any) => {
				console.log(res);
				this.notificacionesSinVer = res.notificacionesCantSinVer;
			});
			this.chatControl.consultarChatsUsuario().subscribe((res) => {
				
				this.ChatsSinVer = res.length;
				res.forEach((chat) => {
					if (chat.visto) {
						this.ChatsSinVer--;
					}
				});
			});
			this.verOfertas();
		}
		this.autho.UsuarioSubsCribe().subscribe(res => {
			console.log(res)
			this.userData = res;
		});
		if (localStorage.getItem('userId')) {
			this.router.navigate(['/home']).then(() => {
				  /* setTimeout(() => {
					location.reload()
				  }, 500); */
				})
		  }else{
				  const laNav = document.querySelector('.navbar ') as HTMLElement;
			  laNav.classList.remove('login_fondo');
			  }
	}
	ionViewDidEnter() {
		if (localStorage.getItem('userId')) {
			this.router.navigate(['/home']).then(() => {
				  /* setTimeout(() => {
					location.reload()
				  }, 500); */
				})
		  }else{
				  const laNav = document.querySelector('.navbar ') as HTMLElement;
			  laNav.classList.remove('login_fondo');
			  }
	}
	async tienda_app(url: any) {
		try {
			await this.util.loading();
			this.router.navigate([url]);
			await this.util.closeLoading();
		} catch (error) {}
	}
	isDiffToAnonimo(){
		const tipo = localStorage.getItem('tipo')
		return tipo !== 'Anonimo'
	}
	notificacionesF() {
		this.notificaciones.limpiarNotificaciones(this.uid);
		this.router.navigate(['/notificaciones']);
	}
	verOfertas(){
		this.ofertaSv.consultarOfertaXUser(this.uid).subscribe(
			(res: Oferta[]) => {
				for(const oferta of res) {
					this.OfertaSub(oferta)
				}
			},
			error => {
				console.log(error)
			}
		)
	}
	OfertaSub(oferta: Oferta){
		const ofertaSubs = this.ofertaSv.consultarOferta(oferta.ofertaId).subscribe(
			(res2: Oferta ) =>{
				if(!res2.isPendiente){
					ofertaSubs.unsubscribe()
					if(res2.isAceptado){
						res2.producto.price = Number(res2.precio).toString()
						this.carritoService.AgregarProducto(res2.producto)
					}
				}
			},
			error => {
				console.log(error)
			}
		)

	}

	async menuOpen() {
		setTimeout(() => {
		  this.carritoService.isOpen.next(true)
		}, 0);
	}
	
	cerrar_sesion() {
		this.autho.logout();
	}
}
