import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiKitController } from 'src/app/services/uikitcontroller.service';

@Component({
  selector: 'app-form-enlaces',
  templateUrl: './form-enlaces.component.html',
  styleUrls: ['./form-enlaces.component.scss'],
})
export class FormEnlacesComponent implements OnInit {
  @Input() data: any;
titulo:any;
lg:any = localStorage.getItem('lg');
  constructor(
    private util: UiKitController,
    private modalController: ModalController,
  ) { 
   
  }

  ngOnInit() {
    console.log(this.data);
    if (this.lg.toLowerCase() === 'en') {
        if (this.data.toLowerCase() === 'terminos') {
            this.titulo = 'Terms and conditions';
        }
        if (this.data.toLowerCase() === 'policy') {
            this.titulo = 'Privacy Policy';
        }
        if (this.data.toLowerCase() === 'protection') {
            this.titulo = 'Data protection';
        }
        if (this.data.toLowerCase() === 'standard') {
            this.titulo = 'Standard cnlyntract between fan and creator ';
        }
        if (this.data.toLowerCase() === 'resource') {
            this.titulo = 'ANTI-SLAVERY AND ANTITRAFFICKING STATEMENT 2023 ';
        }
        if (this.data.toLowerCase() === 'dmc') {
            this.titulo = 'DMCA Takedown Policy';
        }
        if (this.data.toLowerCase() === 'usc') {
            this.titulo = 'USC 2257';
        }
        if (this.data.toLowerCase() === 'cookie') {
            this.titulo = 'COOKIE NOTICE';
        }
    }
    if (this.lg.toLowerCase() === 'al') {
          if (this.data.toLowerCase() === 'terminos') {
            this.titulo = 'AGBs';
          }
          if (this.data.toLowerCase() === 'policy') {
            this.titulo = 'DATENSCHUTZ-BESTIMMUNGEN';
          }
          if (this.data.toLowerCase() === 'protection') {
            this.titulo = 'Datenschutzerklärung';
        }
        if (this.data.toLowerCase() === 'standard') {
          this.titulo = 'Standardvertrag zwischen Fan und Ersteller';
        }
        if (this.data.toLowerCase() === 'resource') {
          this.titulo = 'Erklärung zur Bekämpfung von Sklaverei und Menschenhandel 2023';
        }
        if (this.data.toLowerCase() === 'usc') {
          this.titulo = 'USC 2257';
        }
        if (this.data.toLowerCase() === 'cookie') {
          this.titulo = 'COOKIE-HINWEIS';
        }
    }
  }
  async dismissModal() {
    await this.util.cargando();
    this.modalController.dismiss();
    await this.util.closeLoading(); 
  }
}
